
@font-face {
    font-family: 'gulax Regular';
    src: url('gulax-webfont.ttf');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'orbitron';
    src: url('orbitron-bold-webfont.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserrat';
    src: url('Montserrat-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

.home{
  .welcome{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 180px 0;
    background-image: url("../../assets/svg/bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    .title{
      font-family: gulax Regular,sans-serif;
      color: #fff;
      font-size: 100px;
      text-align: left;
    }
    .mint{
      padding: 40px;
      display: flex;
      flex-direction: column;
      .numbers{
        color: #81EC71;
        text-shadow: 0 0 10px #81EC71;

      }
      .mint-button{
        width: 120px;
        margin-top: 30px;
        align-self: center;
      }
      .MuiLinearProgress-colorPrimary{

      }
      .MuiLinearProgress-root{
        height: 30px;
        border: 1px solid #fff;
        box-shadow: 0 0 10px #81EC71;
        margin-top: 20px;
        background-color: transparent;
        border-radius: 15px;
        .MuiLinearProgress-barColorPrimary{
          background-color: #81EC71;
          border-radius: 15px;
        }
      }
    }

    .MuiButton-root{
      font-family: orbitron,serif;
      color: #000;
      background-color: #81EC71;
      border-color: #81EC71;
      line-height: normal;
      min-height: 35px;
      padding: 0 30px;
      text-transform: none;
      border-radius: 20px
    }
  }
  .part{
    border-top: 2px solid #fff;
    background-color: #000;
    display: flex;
    padding: 80px 60px;
    flex: 1;
  }
  .part-1{
    .left{
      display: flex;
      flex: 1;
      align-items: flex-start;
      text-align: left;
      margin-right: 60px;
      justify-content: center;
      flex-direction: column;
    }
    .right{
      display: flex;
      flex: 1;
      video, img{
        max-width: 100%;
      }
    }
  }
  .part-2{
    .left{
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    .left-item-container{
      display: flex;
      align-items: center;
      flex: 1
    }
    .left-item{
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      padding: 20px;
      height: 300px;
      img{
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .right{
      padding: 0 40px;
      justify-content: center;
      display: flex;
      text-align: left;
      flex-direction: column;
      flex: 1;
    }
  }
  .part-title{
    font-family: gulax Regular,sans-serif;
    font-size: 40px;
    color: #81EC71;
    margin-bottom: 20px;
  }
  .part-content{
    font-family: montserrat,sans-serif;
    color: white;
    line-height: 26px;
  }
}
.monsters{
  background-color: #db62a7;
  padding: 40px 40px 0 40px;
  ul{
    display: flex;
    flex-wrap: wrap;
    .monsters-item{
      display: flex;
      flex-direction: column;
      width: 40%;
      text-align: left;
      margin-right: 40px;
      background-image: linear-gradient(to bottom left, #a186cd, #e39ac7);
      margin-bottom: 80px;
      padding: 20px;
      border-radius: 10px;
      .avatar{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        img{
          max-width: 100%;
        }
      }
      .name{
        font-family: gulax Regular,sans-serif;
        color: #fff;
        font-size: 1.8em;
        text-shadow: 0 0 6px #81EC71;
        margin-top: 10px;
      }
      .attr{
        color: #81EC71;
        margin: 20px 0;
        span{
          margin-right: 10px;
        }
      }
      .intro{
        font-family: montserrat,sans-serif;
        color: #000;
        line-height: 26px;
      }
      .intro-indent{
        float: left;
        clear: left;
        text-align: justify;
        &:first-letter{
          color: #81EC71;
          font-weight: bold;
          padding-right: 10px;
          font-size: 3em;
          line-height: 100%;
          float: left;
        }
      }
    }
  }
}

.MuiPaper-rounded {
  border-radius: 15px !important;
}

.Component-closeButton-2 {
  color: #000 !important;
}

.MuiDialog-paper{
  background: linear-gradient(35.2deg, #83F272 12.05%, #AED3FF 54.26%, #FF7CC8 98.51%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 18px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 30px;
  .MuiDialogTitle-root{
    .MuiTypography-root{
      font-family: orbitron,serif !important;
    }
    background-color: transparent !important;
  }
  .MuiList-root{
    background-color: transparent !important;
    .MuiListItem-root{
      .MuiButton-root{
        font-family: orbitron,serif !important;
      }
    }
  }

  .MuiSvgIcon-root{
    color: #000;
  }
  .dialog-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    .btn-group-custom{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      .MuiButton-root{
        font-family: orbitron,serif;
        color: #000;
        background-color: #DB62A8;
        border-color: #DB62A8;
        line-height: normal;
        min-height: 35px;
        padding: 0 30px;
        text-transform: none;
        border-radius: 20px
      }
      .btn-confirm{
        background-color: #81EC71;
      }
      .btn-cancel{
        color: #81EC71;
        margin-top: 10px;
        background-color: rgba(0, 0, 0, 0.6)
      }
      .el-button+.el-button {
        margin-left: 0;
      }
    }
    .logo{
      width: 100px;
    }
    .title{
      text-align: left;
      margin-top: 30px;
      color: #FFFFFF;
      font-family: gulax Regular,sans-serif;
      text-shadow: 0px 0px 6px rgba(123, 254, 105, 0.72);
    }
    .price{
      text-align: left;
      margin-top: 20px;
      color: #000;
      margin-bottom: 15px;
    }
    .errorTip{
      color: #fff;
      font-family: montserrat,sans-serif;
      text-align: left;
    }
    .MuiTextField-root{
      width: 100%;
      .MuiInput-underline:before{
        display: none;
      }
      .MuiInput-underline::after{
        display: none;
      }
      .MuiInputBase-input{
        border: 1px solid #81EC71;
        padding: 8px;
        background: black;
        border-radius: 30px;
        color: #81EC71;
      }
    }
    .nums{
      display: flex;
      font-weight: normal;
      color: #000;
      justify-content: space-between;
      margin-bottom: 14px;
    }
    .total{
      text-align: right;
      margin-top: 30px;
      color: #000;
    }
  }
}

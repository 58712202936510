.footer{
  display: flex;
  flex: 1;
  background-color: #000;
  padding: 66px 86px;
  color: #fff;
  .left{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    .link a{
      margin-right: 40px;
    }
    .subscribe img{
      width: 30px;
      margin-right: 70px;
    }
  }
  .right{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: flex-end;
    .title{
      font-family: gulax Regular,sans-serif;
      color: #81EC71;
      margin-bottom: 12px;
    }
    .tip{
      font-family: montserrat,sans-serif;
    }
    .bottom{
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-top: 70px;
      img{
        width: 100px;
      }
    }
  }
}

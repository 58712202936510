#nav {
  height: 50px;
  display: flex;
  background-color: #81EC71;
  padding: 8px 40px;
  font-size: 18px;
  .MuiButton-root{
    font-family: orbitron,serif;
    color: #000;
    background-color: #DB62A8;
    border-color: #DB62A8;
    line-height: normal;
    min-height: 35px;
    padding: 0 30px;
    text-transform: none;
    border-radius: 20px
  }
  .activeLink {
    color: #DB62A8;
  }
  .nav-header{
    display: flex;
    flex: 1;
    align-items: center;
  }
  .nav-left{
    li{
      margin-right: 44px;
    }
  }
  .nav-right{
    justify-content: flex-end;
    li{
      margin-left: 27px;
    }
    .mint-button{
      ma
      span{

      }
    }
  }

  a {
    font-weight: bold;
    color: #000;

    &.router-link-exact-active {
      color: #DB62A8;
    }
  }
}

.mine{
  box-sizing: border-box;
  .top{
    background-color: #FF7DC8;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #000;
    border-top: 2px solid #000;
    h1{
      font-family: gulax Regular, sans-serif;
      color: #fff;
      font-weight: normal;
    }
  }
  .container{
    padding: 44px 88px;
    .title{
      font-family: montserrat, sans-serif;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .list{
      display: flex;
      flex-wrap: wrap;
      margin: 44px 150px;
      .list-item{
        box-sizing: border-box;
        padding: 0 50px;
        display: flex;
        flex-direction: column;
        width: 33.3%;
        text-align: left;
        margin-bottom: 44px;
        img{
          max-width: 100%;
        }
        .name{
          margin-top: 22px;
          font-family: gulax Regular, sans-serif;
          font-size: 20px;
        }
        .other{
          font-family: montserrat, sans-serif;
          .birth{
            margin-top: 10px;
          }
          .download{
            cursor: pointer;
            margin-top: 10px;
            img{
              width: 16px;
              margin-right: 8px;
            }
          }
        }
        .line{
          width: 100%;
          height: 2px;
          background-color: #FF7DC8;
          margin-top: 35px;
        }
      }
    }
  }
}

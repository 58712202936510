.banner{
  background-color: #81EC71;
  padding: 40px 0;
  color: #000;
  .title{
    font-family: gulax Regular,sans-serif;
    font-weight: normal;
    font-size: 2.5em;
  }
  .content{
    margin-top: 20px;
  }
}
